import React from 'react';

import { connect } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { compose } from 'redux';

import history from '../history';
import AdminLayout from '../layouts/Admin';
import AuthLayout from '../layouts/Auth';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const App = ({ token }) => {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/auth" component={AuthLayout} />
        <Route
          path="/admin"
          render={props => {
            if (token) {
              return <AdminLayout {...props} />;
            } else {
              window.requestedUrl =
                window.location.pathname + history.location.search;
              return <Redirect to="/auth/login" />;
            }
          }}
        />
        <Redirect from="/" to="/admin/dashboard" />
      </Switch>
      <ToastContainer
        style={{ fontSize: '14px' }}
        position="top-right"
        autoClose={6000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Router>
  );
};

const mapStateToProps = ({ Auth }) => ({ token: Auth.token });

const enhance = compose(connect(mapStateToProps, null));

export default enhance(App);
