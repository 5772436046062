import { useEffect, useState } from 'react';
import update from 'immutability-helper';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect, useSelector } from 'react-redux';
import { combineStyles, sortByKey, sortByNestedKey } from 'helpers/helpers';

import { fetchDiets } from 'actions/Diets';
import { openSweetToast } from 'actions/App';
import { fetchMealTypes } from 'actions/MealTypes';
import { addVariant, editVariant, fetchVariant } from 'actions/Variants';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import PromptContent from '../PromptContent';
import { v4 as uuidv4 } from 'uuid';

import List from '@material-ui/core/List';
import Dialog from '@material-ui/core/Dialog';
import ListItem from '@material-ui/core/ListItem';
import DialogTitle from '@material-ui/core/DialogTitle';
import ListItemText from '@material-ui/core/ListItemText';
import DialogContent from '@material-ui/core/DialogContent';

import { withTranslation } from 'react-i18next';
import FormVariantsView from './FormVariants.view';
import { initialState } from './initialState';
import { validateForm } from './auixiliumFuncionts';

const FormVariantsContainer = ({
  classes,
  match,
  location,
  fetchMealTypes,
  fetchDiets,
  fetchVariant,
  mealTypes,
  variant,
  t,
  editVariant,
  addVariant,
  history,
  openSweetToast,
  diets,
}) => {
  const [state, setState] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);

  const editId = match?.params.id;
  const isEdit = location?.pathname.includes('edit');
  let initialMealTypes = [];

  const { multinational } = useSelector(
    ({
      Brands: {
        brand: { multinational },
      },
    }) => ({
      multinational,
    })
  );

  useEffect(() => {
    Promise.all([fetchMealTypes(), fetchDiets()]).then(res => {
      editId &&
        fetchVariant(match.params.id).then(variant => {
          let givenVariant = variant;
          const mealTypesAfterFetch = res[0].filter(mealType =>
            givenVariant.mealTypes.includes(mealType['@id'])
          );

          initialMealTypes = mealTypesAfterFetch.sort(
            (a, b) => a.position - b.position
          );
          setState(prev => ({
            ...prev,
            variant: {
              ...givenVariant,
              mealTypes: mealTypesAfterFetch,
              calories: sortByKey(givenVariant.calories, 'position').map(
                el => ({
                  ...el,
                  key: uuidv4(),
                })
              ),
            },
            image: givenVariant.image?.['@id'] || null,
            imageUrl: givenVariant.image?.contentUrl || null,
          }));
          setIsLoading(true);
        });
    });
    !isEdit && setIsLoading(true);
  }, []);

  const setCalories = (dragIndex, hoverIndex, dragRow) => {
    setState(prev => ({
      ...prev,
      variant: update(state.variant, {
        calories: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        },
      }),
    }));
  };

  const handleNameChange = e => {
    setState(prev => ({
      ...prev,
      variant: update(state.variant, {
        name: { $set: e.target.value },
      }),
    }));
  };

  const handleVariantName = (calorie, variant, e) => {
    calorie.name = e.target.value;
    setState(prev => ({ ...prev, variant: variant }));
  };

  const handleVariantWorkName = (calorie, variant, e) => {
    calorie.workName = e.target.value;
    setState(prev => ({ ...prev, variant: variant }));
  };

  const toggleCalorificActivness = (calorie, variant) => {
    calorie.active = !calorie.active;
    setState(prev => ({ ...prev, variant }));
  };

  const removeRow = (variant, index) => {
    let calories = variant.calories;
    calories.splice(index, 1);
    setState(prev => ({
      ...prev,
      variant: update(state.variant, {
        calories: { $set: calories },
      }),
    }));
  };

  const handleShortNameChange = e => {
    setState(prev => ({
      ...prev,
      variant: update(state.variant, {
        shortName: { $set: e.target.value },
      }),
    }));
  };

  const handlePositionChange = e => {
    setState(prev => ({
      ...prev,
      variant: update(state.variant, {
        position: { $set: parseInt(e.target.value) },
      }),
    }));
  };

  const handleChangeIntField = e => {
    setState(prev => ({
      ...prev,
      variant: update(state.variant, {
        [e.target.name]: { $set: parseInt(e.target.value) },
      }),
    }));
  };

  const handleDiets = (e, selected) => {
    setState(prev => ({
      ...prev,
      variant: update(state.variant, {
        diets: { $set: e.target.value },
      }),
    }));
  };

  const toggleCheckboxState = name => {
    setState(prev => ({
      ...prev,
      variant: update(state.variant, {
        [name]: {
          $set: !state.variant?.[name],
        },
      }),
    }));
  };

  const handleToggle = name => {
    setState(prev => ({
      ...prev,
      variant: update(state.variant, {
        [name]: { $set: !state.variant[name] },
      }),
    }));
  };

  const handleMealTypes = (e, selected) => {
    let variant = state.variant;
    let mealTypes = selected.map(el => el['@id']);
    variant.calories.forEach((calorie, rowIndex) => {
      calorie.sizes = calorie.sizes.filter(size => {
        return mealTypes.includes(size.mealType);
      });
    });

    setState(prev => ({
      ...prev,
      variant: update(variant, {
        mealTypes: { $set: selected },
      }),
    }));
  };

  const handleDescriptionChange = e => {
    setState(prev => ({
      ...prev,
      variant: update(state.variant, {
        description: { $set: e.target.value },
      }),
    }));
  };

  const handleInternalPositionChange = e => {
    setState(prev => ({
      ...prev,
      variant: update(state.variant, {
        internalPosition: { $set: parseInt(e.target.value) },
      }),
    }));
  };

  const addCalorie = () => {
    setState(prev => ({
      ...prev,
      variant: update(state.variant, {
        calories: {
          $set: [
            ...state.variant.calories,
            {
              name: '',
              workName: '',
              sizes: [],
              active: true,
              superVariantMealType: [],
              key: uuidv4(),
            },
          ],
        },
      }),
    }));
  };

  const getSizeName = (row, mealTypeId) => {
    let editedVariant = { ...state.variant };
    let calorie = editedVariant.calories[row];
    let editedSize = calorie.sizes.find(size => size.mealType === mealTypeId);
    if (!editedSize) {
      return <button className={'variant-sizeButton'}>+</button>;
    }
    let size = mealTypes
      .find(mealType => mealType['@id'] === mealTypeId)
      .sizes.find(size => {
        return size['@id'] === editedSize.size;
      });

    if (typeof size === 'undefined') {
      size = { size: { name: 'BRAK' }, calories: 0 };
    }
    return (
      <button className={'variant-sizeFilled'}>
        {size.size.name} - {size.calories} kcal
      </button>
    );
  };

  const getImage = (stateName, data) => {
    setState(prev => ({
      ...prev,
      [stateName]: data?.['@id'] || null,
      [`${stateName}Url`]: data?.contentUrl || null,
    }));
  };

  const removeImage = stateName => {
    setState(prev => ({
      ...prev,
      [stateName]: null,
      [`${stateName}Url`]: null,
    }));
  };

  const getRowSum = row => {
    let sum = 0;

    row.sizes.forEach(size => {
      mealTypes.forEach(mealType => {
        if (mealType['@id'] === size.mealType) {
          mealType.sizes.forEach(mtSize => {
            if (mtSize['@id'] === size.size) {
              sum += mtSize.calories;
            }
          });
        }
      });
    });

    return `${sum} kcal`;
  };

  const closeModal = () => {
    setState(prev => ({ ...prev, modal: null }));
  };

  const openModal = (rawSizes, row, mealTypeId) => {
    const sizes = sortByNestedKey(rawSizes, 'size', 'position');

    setState(prev => ({
      ...prev,
      modal: (
        <Dialog open={true} onClose={closeModal}>
          <DialogTitle>
            {t(
              'form.field.variant.dialogTitle.choiceMealSize',
              'Wybierz wielkość posiłku'
            )}{' '}
          </DialogTitle>
          <DialogContent>
            <List>
              {sizes.length > 0 ? (
                sizes.map((size, index) => (
                  <ListItem
                    className={'variant-listItem'}
                    style={{
                      cursor: 'pointer',
                      textAlign: 'center',
                    }}
                    key={index}
                    onClick={() => {
                      let editedVariant = { ...state.variant };
                      let calorie = editedVariant.calories[row];
                      let editedSize = calorie.sizes.find(
                        size => size.mealType === mealTypeId
                      );
                      if (!editedSize) {
                        editedSize = { mealType: mealTypeId, size: null };
                        calorie.sizes.push(editedSize);
                      }
                      editedSize.size = size['@id'];
                      closeModal();
                    }}
                  >
                    <ListItemText>
                      {size.size.name} - {size.calories}{' '}
                      {t('"form.field.variant.kcal": "kcal",')}
                    </ListItemText>
                  </ListItem>
                ))
              ) : (
                <div>
                  <h1>
                    {t(
                      'form.field.variant.dialogContent.notExistsSizes',
                      'Nie istnieją żadne wielkości przypisane do tego typu posiłku.'
                    )}
                  </h1>
                </div>
              )}
            </List>
          </DialogContent>
        </Dialog>
      ),
    }));
  };

  const handleSubmit = async () => {
    if (!validateForm(state, t)) {
      return;
    }
    const data = {
      ...state.variant,
      image: state.image,
      mealTypes: state.variant.mealTypes.map(mealType => mealType['@id']),
      calories: state.variant.calories.map((calorie, index) => {
        calorie.position = index;
        calorie.superVariantMealType = calorie.superVariantMealType.map(el => ({
          ...el,
          mealType: el.mealType['@id'],
        }));
        return calorie;
      }),
    };

    if (isEdit) {
      try {
        if (showWarningModal()) {
          await sweetToastPrompt();
        }
        await editVariant(match.params.id, data);
        history.push('/admin/variants');
      } catch (err) {}
    } else {
      addVariant(data).then(() => {
        history.push('/admin/variants');
      });
    }
  };

  const showWarningModal = () =>
    !initialMealTypes.every(initialMealType =>
      state.variant.mealTypes.some(
        stateMealType => stateMealType.id === initialMealType.id
      )
    );

  const sweetToastPrompt = function () {
    return new Promise((resolve, reject) => {
      openSweetToast({
        style: { display: 'block' },
        title: t('variants.save.prompt.title'),
        content: <PromptContent />,
        showCancel: true,
        onConfirm: resolve,
        onCancel: reject,
        confirmBtnText: t('common.shared.yes', 'Tak'),
        cancelBtnText: t('common.shared.no', 'Nie'),
      });
    });
  };

  const props = {
    state,
    setState,
    isLoading,
    isEdit,
    setCalories,
    handleNameChange,
    handleVariantName,
    handleVariantWorkName,
    toggleCalorificActivness,
    removeRow,
    handleShortNameChange,
    handlePositionChange,
    handleChangeIntField,
    handleDiets,
    toggleCheckboxState,
    handleToggle,
    handleMealTypes,
    handleDescriptionChange,
    handleInternalPositionChange,
    addCalorie,
    getSizeName,
    getImage,
    removeImage,
    getRowSum,
    openModal,
    handleSubmit,
    classes,
    mealTypes,
    variant,
    t,
    diets,
    multinational,
  };

  return <FormVariantsView props={props} />;
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapStateToProps = state => {
  return {
    variant: state.Variants.variant,
    mealTypes: state.MealTypes.mealTypes,
    diets: state.Diets.diets,
  };
};

const mapDispatchToProps = {
  fetchMealTypes,
  fetchDiets,
  addVariant,
  editVariant,
  fetchVariant,
  openSweetToast,
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(combinedStyles)(FormVariantsContainer))
);
